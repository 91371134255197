import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import ButtonOutline from "../Buttons/ButtonOutline";

const PricingModal = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ButtonOutline text="View Pricing" onClick={handleClickOpen} />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClick={handleClose}>
          PAY ONLY WHEN YOU RECEIVE AN ORDER
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            When you join you don’t need to pay us any registration charges or
            subscription fees instead we give you complimentary 2 months in
            which you don’t need to pay us any commission.
          </Typography>
          <Typography gutterBottom>
            After your 2 months expire we charge only a 10% commission, only if
            you recieve orders from ChefCities.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PricingModal;
