import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={{ margin: 15 }}>
      <h1 className="text-center mb-3">Terms and Conditions</h1>
      <p>
        ChefCities<span className="logo-dark-gold">.com</span>{" "}
      </p>

      <p>
        This document is an electronic record in terms of Information Technology
        Act, 2000 and rules there under as applicable and the amended provisions
        pertaining to electronic records in various statutes as amended by the
        Information Technology Act, 2000. This document is published in
        accordance with the provisions of Rule 3 (1) of the Information
        Technology (Intermediaries guidelines) Rules, 2011 that require
        publishing the rules and regulations, privacy policy and Terms of Use
        for access or usage of www.chefcities.com website and handheld devices.{" "}
      </p>

      <h6>TERMS OF USE</h6>
      <p>
        If you do not agree to any of the following terms and conditions you are
        requested to stop using this website immediately
      </p>
      <p>
        This platform is a partnership incorporated under the partnership act,
        1932.
      </p>

      <h6>SERVICE AND REGISTRATION</h6>

      <p>
        We will provide a platform to place an order with a Seller for an item
        of your choice (example: Cake) on the Website, along that we provide the
        seller with delivery services.
      </p>
      <p>
        As part of the Service, we will process all the payments relating to the
        order and, where required, liaise with the Seller on your behalf in
        accordance with this document.
      </p>
      <p>
        {" "}
        We warrant that the Service will be provided by us using reasonable care
        and skill.
      </p>
      <p>
        {" "}
        At the time of registration, the Company shall collect the following
        personally identifiable information about you: Name – including first
        and last name and contact number
      </p>
      <p>
        {" "}
        While placing an order the Company shall collect information regarding
        delivery information.
      </p>
      <p>
        {" "}
        We provide access to the Service on a temporary basis, and we reserve
        the right to withdraw the Service without notice.
      </p>
      <p>
        {" "}
        We will not be liable if for any reason the Service is unavailable at
        any time or for any period.
      </p>

      <h6>INTELLECTUAL PROPERTY</h6>

      <p>
        {" "}
        We own or are the licensee to the copyright and other intellectual
        property rights in the Service, including the Website and the materials
        published on the Website.
      </p>
      <p> No use of the information is permitted.</p>
      <p>
        {" "}
        Any portion of the Site may not be reproduced, duplicated, copied, sold,
        resold, visited, or otherwise exploited for any commercial purpose
        without an express written consent of the Company.
      </p>
      <p>
        {" "}
        No part of the trademark, logo, or other proprietary information
        (including images, text, page layout, or form) of the Company can be
        used. Any such unauthorized use can lead to legal action.
      </p>

      <h6>CONTRACT WITH SELLER</h6>

      <p>
        {" "}
        We have been instructed by the seller as their agent to conclude the
        Contract of Sale with the Client and accept, refund and process all
        payments relating to the sale of their items via the Website.
      </p>
      <p>
        For any avoidance of doubt, we are not a party to any Contract of Sale.
        We have no control over, the information on the Website relating to the
        items or the Sellers, or the actions or omissions of any Seller relating
        to the Contract of Sale. We cannot be held liable for any such breach of
        contract between the Seller and the Client.
      </p>
      <p>
        The legal contract of the sale of items purchased via ChefCities is
        between the customer and the seller from whom the customer places the
        order and such Contract of Sale shall comprise these terms, the
        description given on the Website for the item and you agree to be bound
        by all such provisions.
      </p>
      <p>
        When a customer enters into a contract with the seller there is also a
        contract formed with ChefCities solely for the purpose of payment of
        commission and providing delivery services to the seller.
      </p>
      <p>
        {" "}
        We only act as an intermediary and have the right to be paid a specified
        commission on every order placed on the website.
      </p>
      <p>
        {" "}
        A client will be liable to be sued for breach of contract if he/she does
        not complete payment or directly contacts the seller via our website
        with the sole intention of not paying commission.
      </p>

      <h6>ORDERS</h6>

      <p>
        {" "}
        All information about ingredients of the product has been provided by
        the seller is uploaded by us and we are in no way responsible for
        incorrect information provided by the seller.
      </p>
      <p>
        If in case of doubt you have any concerns regarding allergens please
        contact ChefCities before placing an order with the seller. We will
        contact the seller on your behalf and solve your query. We will in no
        way be responsible if the product delivered by the seller is not in
        compliance with the customer’s request.
      </p>
      <p>
        All goods selected by a customer are added to the cart. From the cart
        the customer may place his/her order.
      </p>
      <p>
        A customer is not allowed to place orders from two different sellers and
        will be notified of the same.
      </p>
      <p>
        Once we have received your Order, we will submit it to your chosen
        Seller and you will receive an order confirmation on the website itself
        to confirm that your Order has been received and is being processed.
        Unless otherwise stated, your Order must allow at least 1 clear working
        days (not including bank holidays) for the Seller to bake your order
        (for instance if you want the order to be delivered on a Friday, you
        must submit your Order on Tuesday allowing the Seller Wednesday and
        Thursday to bake).
      </p>
      <p>
        If a seller is unable to fulfil your order you will be informed about it
        through the website or via a phone call. As soon as we are informed that
        the seller is unable to fulfil the order we will inform the seller. The
        sellers cannot be held liable for any cancellation as they are not party
        to the contract between client and seller.
      </p>
      <p>
        Once an order is placed you may not cancel it. However if you wish to
        cancel it you may contact us on +91 9967866352 and we will attempt to
        contact the seller regarding the cancellation. We do not guarantee that
        the seller will accept this cancellation. ChefCities will inno way be
        responsible or liable for a refund in this scenario.
      </p>
      <p>
        We are in no way liable if there is any harm caused by the seller’s
        product to the client.
      </p>

      <h6>IMAGES AND LOGO</h6>

      <p>
        All the images and logos on the site are uploaded by Chefcities and is
        therefore the intellectual property of the company.
      </p>
      <p>
        {" "}
        The images or logos cannot be used for any purpose without the written
        consent of the company.
      </p>
      <p>
        The use of any logo or images without the written consent will be liable
        to face legal action.
      </p>

      <h6>DELIVERY</h6>

      <p>
        {" "}
        Neither we nor the Seller will not guarantee that an Order will be
        delivered on the delivery date/within the time you have selected but we
        and the Seller will do our best to ensure that the Order is delivered on
        time.
      </p>
      <p>
        {" "}
        Neither we nor the Seller can accept any responsibility or liability
        arising from late delivery of your order.
      </p>
      <p>
        If no one is present at the delivery address the seller will keep the
        product till its shelf life. If the seller disposes the product in this
        scenario the customer will not be entitled to a refund.
      </p>
      <p>
        Neither we nor the Seller shall be liable for any delay or failure in
        the delivery of the items that is caused by your failure to provide the
        Seller with adequate delivery instructions or any other instructions
        that are relevant to the supply of the items including erroneous
        address, lack of permission to enter or refusal of the order.{" "}
      </p>

      <h6>PAYMENT</h6>

      <p>
        {" "}
        The price of the product will not include delivery charge. Delivery
        charge will be shown on the checkout page.
      </p>
      <p>
        It is always possible that, despite our and the Seller’s best efforts,
        some of the items may be incorrectly priced.
      </p>
      <p>
        If the product’s price at your checkout page is incorrect, we will
        contact you to confirm the correct price before the Seller starts
        processing your order.
      </p>
      <p>
        If the corrected price is not favourable to you, you may choose not to
        go ahead with the order. You will not be charged for this order.
      </p>
      <p>
        You may pay the price for the product via UPI or via your debit/credit
        cards or any other means offered by our payment gateway.{" "}
      </p>
      <p>
        If after placing an order the seller is unable to cook the product due
        to unforeseen circumstances you will not be charged for the product and
        will be informed about the cancellation.
      </p>

      <h6>DISCLAIMER</h6>

      <p>
        We try to accurately display all the ingredients of the product but it
        is the sellers responsibility to provide us this information accurately.
        <p>
          {" "}
          We are not responsible for any information provided by the seller
          inaccurately.
        </p>
        <p>Items may vary slightly from their pictures.</p>
        <p>
          {" "}
          The images of the item on the Website are uploaded by the Sellers and
          are for illustrative purposes only.
        </p>
        <p>
          {" "}
          Your order may vary slightly from those images as each one is
          homemade.
        </p>
        <p>
          {" "}
          Neither we nor the Seller guarantee that the order that is delivered
          to the Recipient will be identical to the item in the photograph.
        </p>
        <p>
          Product packaging may vary. The packaging of the order may vary. The
          Seller will undertake pack each item in a secure and hygienic manner.
        </p>
        <p>
          {" "}
          We do not give any undertaking, assurance or warranty about the
          Sellers on the Website and we disclaim any such undertakings,
          assurances or warranties.
        </p>
        <h6>WARRANTIES AND LIABILITIES</h6>
        <p>
          {" "}
          To the extremist extent permitted by applicable laws, we disclaim
          responsibility for any harm resulting from your use of any part of the
          Service.
        </p>
        <p>
          The Service is provided on an “as is” basis and we expressly disclaim
          to the fullest extent permitted by law all express, implied and
          statutory warranties, including the implied warranties of title,
          non-infringement, merchantability or fitness for a particular purpose.
        </p>
        <p>
          To the fullest extent we disclaim all liability and responsibility
          relating to all products on the website.
        </p>
        <p>
          Nothing in these Terms shall limit or exclude our liability for
          fraudulent misrepresentation (only contractual in Ind), for death or
          personal injury resulting from our negligence or the negligence of our
          agents or employees (purely tortious in Ind OR is criminal which will
          also lie on the Seller) or for any other liability that cannot be
          limited or excluded by law. Nothing in these Terms affects your
          statutory rights. (was already there – not statutorily needed in
          India).
        </p>
        <p>
          Our total liability to you in respect of all losses arising under or
          in connection with your use, or inability to use any part of the
          Service, whether in contract, tort (including negligence), breach of
          statutory duty or otherwise shall in no circumstances exceed the value
          of the Order or Rs. 1000, whichever is the lower.
        </p>
        <h6>TERMINATION</h6>
        <p>
          {" "}
          Termination of these terms will occur only if you stop using the
          website
        </p>
        <p>
          We may terminate your account if it is believed that your actions may
          cause legal liability for you, other users or the company or if you
          are found non-compliant with the Terms and conditions.
        </p>
        <p>
          Your account may also be terminated for posting any information or
          data that may be obscene, indecent, vulgar, profane, racist, sexist,
          discriminatory, offensive, derogatory, harmful, harassing,
          threatening, embarrassing, malicious, abusive, hateful, menacing,
          defamatory, untrue or political or contrary to our interest.
        </p>
        <h6>FEEDBACK</h6>
        <p>
          {" "}
          Any material you provide in connection with the Service will be
          considered non-confidential and non-proprietary and may be made
          viewable to the public on the Website. This will include any comments,
          feedback or ratings you submit in relation to a Seller.
        </p>
        <p>We reserve our right to remove objectionable reviews and ratings.</p>
        <p>
          {" "}
          You agree to indemnify us against any and all losses, damages and
          claims incurred by or made against us by a Seller or any other third
          party in connection with any review, feedback or ratings you have
          posted or uploaded to the Website.
        </p>
        <p>
          The reviews of other people are only the opinion of the reviewer.{" "}
        </p>
        <p>
          We accept no responsibility for any reviews, ratings or feedback
          posted on the website or the opinions expressed within them relating
          to any seller.
        </p>
        <p>
          Accordingly we accept no liability to any person arising from any
          reviews, including without limitation any mistakes, defamation,
          obscenity, omissions or falsehoods that you may encounter in any such
          materials.
        </p>
        <h6>IMPORTANT TERMS</h6>
        <p>
          {" "}
          If a court finds any part of this contract illegal or unconstitutional
          only that part of the contract will be null and void, rest of the
          terms shall still be valid.
        </p>
        <p>We reserve the right to transfer this agreement to someone else.</p>
        <p>
          {" "}
          We reserve the right to transfer our rights and obligations under
          these terms to another organisation.
        </p>
        <p>
          The customer’s consent is required to transfer their rights to someone
          else. The customer can only transfer his rights to another person if
          we agree to this in writing.
        </p>
        <p>
          Which laws apply to the contract between you and us and where you may
          bring legal proceedings. These terms are governed by Indian law. You
          and we both agree to that the courts of India will have an exclusive
          jurisdiction.
        </p>
        <p>
          You have read and accept the{" "}
          <a href={`${process.env.PUBLIC_URL}/delivery-policy`}>
            Delivery Policy
          </a>{" "}
          as well and the{" "}
          <a href={`${process.env.PUBLIC_URL}/refund-policy`}>Refund Policy</a>.
        </p>
        <h1 id="seller-terms">Seller terms and Conditions</h1>
        <p>
          This is a contract made in Mumbai, Maharashtra, India on this between
          ChefCities and the home chef that herein will be referred as the
          seller.
        </p>
        <p>
          In this document the words ‘you’ and ‘seller’ refer to you the home
          chef and the words ‘we’ , ‘us’, ‘our’ and ‘ours’ refers to ChefCities.
        </p>
        <p>
          You give us the right to contact you via any medium suitable. We may
          contact you to gather detailed information regarding the ingredients
          used in your product or alike.
        </p>
        <p>
          You as a seller agree to take full responsibility of requesting for
          delivery from our delivery partners by clicking on the ‘Request for
          delivery’ option provided on your orders page. Failure in doing so may
          result in a penalty.
        </p>
        <p>
          During delivery you are{" "}
          <b>not permitted to provide your contact details</b> in your packaging
          as a part of the delivery without our written approval. We may provide
          you with stickers or cards of ChefCities which you are required to put
          in or stick on to your packaging. If you as a seller are unable to
          complete delivery of a product due to fault of the customer then you
          have the right to dispose of the product only after expiry of it’s
          shelf life and the customer will not be entitled to a refund. If a
          customer does not respond or does not meet the seller at his address
          the seller will retain the product till its shelf life and then
          dispose of it. ChefCities is in no way liable for failure of
          fulfilment of delivery or any kind of delay in delivery .ChefCities is
          not responsible for any damage caused to the product during delivery.
        </p>
        <p>
          If the customer does not accept the product due to any damage caused
          during delivery then neither ChefCities nor the customer will be
          liable to pay any amount to the seller.
        </p>
        <p>
          You are required to contact our customer service if you intend to be a
          seller on the website. It is your responsibility to have or obtain all
          documents that are required by law to sell your products from home. In
          the event of absence of such documents ChefCities can in no way be
          held liable.
        </p>
        <p>
          After registering you will need to share pictures, logo, pricing and
          ingredients used in the product. The images and logo sent to us by you
          must not contain any contact details and ChefCities has the right to
          use those images and logos for promotional and other business
          purposes. These images and logo must not be an infringement. In case
          of any infringement, ChefCities will not be liable in any way for this
          infringement.
        </p>
        <p>
          As ChefCities acts as an intermediary we reserve the right to receive
          a commission on every order placed on the website. The commision
          ammount will be communicated to you by a representative of
          ChefCities.This commission is subject to change in the future and its
          non-acceptance will lead to immediate termination of your store on the
          website.
        </p>
        <p>
          The price paid by the customer will be transferred to you after
          ChefCities’s commission.
        </p>
        <p>
          {" "}
          You will be sued for breach of contract if you engage directly with a
          customer with the intention of not paying commission.{" "}
        </p>
        <p>
          Every order’s price must be determined by including the taxes payable
          by the seller, we will not be liable for payment of taxes which you
          have to pay on any order received from us.
        </p>
        <p>
          You are responsible to provide ChefCities accurate and complete
          information on pricing, ingredients, allergy warnings and other allied
          information. We will not be responsible if the information provided by
          you is inaccurate or incomplete. You will be required to clear any
          doubts that we present to you on behalf of the customer. Any health
          concerns that arise to the customer due to consuming your products
          will be the sole responsibility of the seller and ChefCities will not
          be held liable in any way.
        </p>
        <p>
          You are responsible for keeping the area of cooking clean and
          maintaining all hygiene standards required. It is also your
          responsibility to provide a safe and clean package to the customer for
          the product ordered.
        </p>
        <p>
          ChefCities also provides FSSAI registration services only. ChefCities
          will only register you on your behalf and we do not give any guarantee
          regarding acceptance of registration. The registration amount paid to
          us will be non-refundable.{" "}
        </p>
        <p>
          It will be your responsibility to keep your cooking area and it’s
          surroundings clean and sanitised in case of a FSSAI inspection. You
          may also be required to visit the FSSAI’s office with the required
          documents.
        </p>
        <p>
          ChefCities reserves the right to receive payment in any method
          suitable and any transactional charges arising due to the same will be
          covered by the company.
        </p>
        <p>
          When an order is received, you will be notified about that order via
          SMS and all the details regarding the order will be provided at
          ChefCities.com. You will also be required to share details regarding
          your address.
        </p>
        <p>
          {" "}
          If you receive an order and are unable to accept it due to unforeseen
          circumstances you need to contact us at the earliest, in this scenario
          the entire amount will be refunded to the customer and you will not
          receive any payment for that particular order. You will also be
          charged a penalty of 20% on that order in this case.
        </p>
        <p>
          The customer is not entitled by a way of right to cancel or amend
          their order or to a refund of the price they have paid. However, the
          customer may contact ChefCities and we will attempt to contact or
          communicate with you. You are not obligated to accept cancellation or
          alteration of the order and will not be penalised for the same by the
          company or the customer.{" "}
        </p>
        <p>
          This contract will be terminated once you stop selling your products
          on the company’s website or any other platform provided by ChefCities.{" "}
        </p>
        <p>
          We will not be responsible for any harm arising from a customer that
          you as the seller have contacted with from this website.
        </p>
        <p>
          {" "}
          This contract is subject to change and you will be updated of the
          same. Non-acceptance of these changes will lead to immediate
          termination of your account on ChefCities.com .
        </p>
      </p>
    </div>
  );
};

export default TermsAndConditions;
