export const externalLinks = {
  buyerPlayStore:
    "https://play.google.com/store/apps/details?id=com.chefcities.app",
  buyerAppStore: "https://apps.apple.com/us/app/chefcities/id1586326011",

  sellerPlayStore:
    "https://play.google.com/store/apps/details?id=com.chefcities.partner",
  sellerAppStore:
    "https://apps.apple.com/us/app/chefcities-partner/id1586428524",
};
