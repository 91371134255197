export const COLORS = {
  PRIMARY: "rgb(238,146,12)",
  PRIMARY_DISABLED: "#e6be84",
  PRIMARY_2: "rgba(205,126,10,0.3)",

  DARK_GREY: "rgb(72,72,72)",
  GREY: "rgb(144,144,144)",
  LIGHT_GREY: "rgb(220,220,220)",

  SECONDARY: "rgb(22,118,173)",
  SECONDARY_DISABLED: "rgb(138,186,214)",

  GREEN: "rgb(0,163,0)",
  GREEN_5: "rgba(0,163,0,0.5)",

  RED: "rgb(255,105,97)",
  RED_5: "rgba(255,105,97,0.5)",

  BLUE: "rgb(100,149,237)",

  YELLOW: "rgb(253,253,125)",
  YELLOW_5: "rgba(253,253,125,0.5)",

  VIOLET: "rgb(149,125,173)",

  WHITE: "rgb(255,255,255)",
};

export const ANIMATION_DURATION = 2000;
