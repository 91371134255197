import Button from "@material-ui/core/Button";
import { AppBar, Toolbar } from "@material-ui/core";
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import AppleIcon from "@material-ui/icons/Apple";
import ShopTwoToneIcon from "@material-ui/icons/Shop";
import React, { CSSProperties } from "react";

interface BottomContainerProps {
  playStoreLink: string;
  appStoreLink: string;
}

const BottomContainer = ({
  playStoreLink,
  appStoreLink,
}: BottomContainerProps) => {
  const buttonStyles: CSSProperties = {
    color: "white",
    boxShadow: "0px 0px 20px 0.5px rgba(238,146,12,0.72)",
  };

  return (
    <HideOnScroll direction={"up"}>
      <AppBar
        style={{
          top: "auto",
          bottom: 0,
          background: "transparent",
          boxShadow: "none",
        }}
        position="fixed"
      >
        <Toolbar style={{ padding: 20, justifyContent: "space-around" }}>
          <Button
            startIcon={<ShopTwoToneIcon />}
            href={playStoreLink}
            size={"large"}
            style={buttonStyles}
            variant="contained"
            color="primary"
          >
            Play Store
          </Button>
          <Button
            startIcon={<AppleIcon />}
            style={buttonStyles}
            href={appStoreLink}
            size={"large"}
            variant="contained"
            color="primary"
          >
            App Store
          </Button>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default BottomContainer;
