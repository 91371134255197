import { buyerHomePage } from "../../data/buyerMockupData";
import Heading from "../Text/Heading";
import SubHeading from "../Text/SubHeading";
import React, { useEffect } from "react";
import TickMark from "../TickMark/TickMark";
import AOS from "aos";
import { ANIMATION_DURATION } from "../../constants/colors";

interface HomePageProps {
  homePageData: typeof buyerHomePage;
}
const HomePage = ({ homePageData }: HomePageProps) => {
  useEffect(() => {
    AOS.init({
      duration: 5000,
    });
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div className="row p-3 so-content">
          <div className="col-sm-6 col-md-6 flex-column d-flex justify-content-center">
            <Heading text={homePageData.heading} />
            <SubHeading alignCenter text={homePageData.subHeading} />
            <div className="mt-4 mx-auto">
              {homePageData.tickMarks.map((obj) => (
                <TickMark key={obj.id} text={obj.text} />
              ))}
            </div>
          </div>
          <div
            className="col-sm-6 col-md-6 d-flex"
            style={{ position: "relative" }}
          >
            <div className="">
              <img
                style={{
                  position: "absolute",
                  right: "30%",
                  width: "70%",
                }}
                src={homePageData.imgPathLeft}
                data-aos="fade-up"
                data-aos-duration={ANIMATION_DURATION}
                alt={homePageData.imgLeftAlt}
              />
              <img
                style={{
                  position: "relative",
                  left: "35%",
                  width: "70%",
                }}
                src={homePageData.imgPathRight}
                data-aos="fade-left"
                data-aos-duration={ANIMATION_DURATION}
                alt={homePageData.imgRightAlt}
              />
            </div>
            <div className="mt-3 mb-5" />
          </div>
          <div className="so-background"></div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
