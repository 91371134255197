import { RouteComponentProps } from "react-router-dom";
import BottomContainer from "../components/BottomContainer/BottomContainer";
import HomePage from "../components/HomePage/HomePage";
import MockupSection from "../components/MockupSection/MockupSection";
import { buyerHomePage, buyerMockupData } from "../data/buyerMockupData";
import React from "react";
import { externalLinks } from "../data/externalLinks";
interface HomeProps extends RouteComponentProps {}

const Home = ({}: HomeProps) => {
  return (
    <div>
      <HomePage homePageData={buyerHomePage} />

      {buyerMockupData.map((obj) => (
        <MockupSection
          key={obj.id}
          reverse={obj.id % 2 === 0 ? true : false}
          heading={obj.heading}
          subHeading={obj.subHeading}
          imagePath={obj.imgPath}
          imageWidth={obj.width}
          injectComponent={obj.component}
        />
      ))}

      <BottomContainer
        playStoreLink={externalLinks.buyerPlayStore}
        appStoreLink={externalLinks.buyerAppStore}
      />
    </div>
  );
};

export default Home;
