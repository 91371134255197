import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./screens/Home";
import Partner from "./screens/Partner";
import Navbar from "./components/Navbar/Navbar";
import NotFound from "./screens/NotFound";
import { ThemeProvider } from "@material-ui/core";
import { lightTheme } from "./themes/lightTheme";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import DeliveryPolicy from "./screens/DeliveryPolicy";
import Footer from "./components/Footer/Footer";
import React from "react";

// todo onclick for buttons

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router basename={process.env.PUBLIC_URL}>
        <div>
          <Navbar />
          <div className="shape"></div>
          <Switch>
            <Route path={"/"} exact component={Home} />
            <Route path={"/partner"} component={Partner} />
            <Route
              path={"/terms-and-conditions"}
              component={TermsAndConditions}
            />
            <Route path={"/privacy-policy"} component={PrivacyPolicy} />
            <Route path={"/refund-policy"} component={RefundPolicy} />
            <Route path={"/delivery-policy"} component={DeliveryPolicy} />
            <Route path={"/"} component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
