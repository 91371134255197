import ContactInfo from "../components/ContactInfo/ContactInfo";
import PricingModal from "../components/Modals/PricingModal";
import React from "react";
export const sellerMockupData = [
  {
    id: 0,
    heading: "BE PART OF OUR HOMECHEF COMMUNITY",
    subHeading: "More than just a platform, We are a community.\n30+ Homechefs",
    imgPath: `${process.env.PUBLIC_URL}/images/shop-logos.png`,
    width: 80,
  },
  {
    id: 1,
    heading: "REGISTERING IS EASIER THAN YOU THINK",
    subHeading:
      "Just drop us a mail or call us to set-up your store on ChefCities.",
    imgPath: `${process.env.PUBLIC_URL}/seller-mockups/auth.png`,
    component: <ContactInfo />,
  },
  {
    id: 2,
    heading: "ZERO COST SET-UP LOW COMMISSION",
    subHeading:
      "You don’t need to worry about any set-up or monthly charges cause there aren’t any. Get complimentary 2 months after which you only pay when you receive orders.",
    imgPath: `${process.env.PUBLIC_URL}/seller-mockups/order-detail.png`,
    component: <PricingModal />,
  },
  {
    id: 3,
    heading: "TRACK ALL YOUR ORDERS",
    subHeading:
      "We are determined to give our chefs and customers the best services. Which is why all the orders are delivered by one of Mumbai’s best delivery services, WeFast.",
    imgPath: `${process.env.PUBLIC_URL}/seller-mockups/all-orders.png`,
  },
];

export const sellerTickMarks = [
  {
    id: 0,
    text: "Let us handle delivery",
  },
  {
    id: 1,
    text: "Track all your orders",
  },
  {
    id: 2,
    text: "Be a part of our homechef community",
  },
  {
    id: 3,
    text: "Get complimentary 2 months",
  },
  {
    id: 4,
    text: "Accept online payments",
  },
];

export const sellerHomePage = {
  tickMarks: sellerTickMarks,
  heading: "LOOKING TO START YOUR JOURNEY ONLINE?",
  subHeading:
    "ChefCities brings all homechefs onto a platform that reduces logistics so that you can focus only on the cooking.",
  imgPathRight: `${process.env.PUBLIC_URL}/seller-mockups/main-right.png`,
  imgPathLeft: `${process.env.PUBLIC_URL}/seller-mockups/main-left.png`,
  imgRightAlt: "ChefCities App Home Screen",
  imgLeftAlt: "ChefCities Partner Login Screen",
};
