import { Typography } from "@material-ui/core";
import React from "react";
import MeraLottie from "../Lottie/Lottie";

interface TickMarkProps {
  text: string;
}

const TickMark = ({ text }: TickMarkProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: 5,
      }}
    >
      <div style={{ maxWidth: 35, marginRight: 5 }}>
        <MeraLottie path="animations/check1.json" />
      </div>
      <Typography className="mt-1">{text}</Typography>
    </div>
  );
};

export default TickMark;
