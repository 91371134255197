import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ElevationScroll from "../ElevationScroll/ElevationScroll";
import SimpleMenu from "../Menu/SimpleMenu";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import useWindowDimensions from "../../hooks/useWindowDimensions";
interface NavbarProps {}

const Navbar = ({}: NavbarProps) => {
  const trigger = useScrollTrigger();
  const { pathname } = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();

  function goToHome() {
    history.push(`/`);
  }
  function goToPartner() {
    history.push(`/partner`);
  }

  return (
    <ElevationScroll>
      <AppBar
        component="div"
        position="sticky"
        style={{ background: trigger ? "#fff" : "#fdf4e6" }}
      >
        <Toolbar
          variant="regular"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={goToHome}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logos/gold-fill-690.png`}
              style={{
                maxWidth: 55,
                paddingRight: 10,
              }}
              alt="ChefCities Logo"
            />
            <Typography
              style={{
                fontSize: width < 446 ? 20 : 25,
                fontWeight: "bold",
                marginTop: 7,
              }}
              color="inherit"
            >
              ChefCities {pathname === `/partner` && <span>Partner</span>}
            </Typography>
          </div>
          {pathname === `/` ? (
            <Button
              className="mt-2"
              color="secondary"
              endIcon={<NavigateNextRoundedIcon style={{ marginBottom: 3 }} />}
              onClick={goToPartner}
            >
              Partner
            </Button>
          ) : (
            <Button
              className="mt-2"
              color="secondary"
              endIcon={<NavigateNextRoundedIcon style={{ marginBottom: 3 }} />}
              onClick={goToHome}
            >
              Home
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

export default Navbar;
