import { useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";

interface SeperatorProps {
  marginHorizotal?: number;
  useColor?: string;
}

const Seperator = ({ marginHorizotal = 50 }: SeperatorProps) => {
  const theme = useTheme();
  let useColor = theme.palette.primary.main;

  const seperatorStyles: CSSProperties = {
    border: `1px solid ${useColor}`,
  };
  seperatorStyles["margin"] = `${marginHorizotal}px 0px`;

  return <div style={seperatorStyles}></div>;
};

export default Seperator;
