import { Button } from "@material-ui/core";
import ButtonOutline from "../components/Buttons/ButtonOutline";
import LinkToSellerPage from "../components/Buttons/ButtonOutline";
import React from "react";
export const buyerMockupData = [
  {
    id: 0,
    heading: "MEET OUR HOMECHEF COMMUNITY",
    subHeading: `More than just a platform, We are a community. ${"\n"} 30+ HomeChefs`,
    imgPath: `${process.env.PUBLIC_URL}/images/shop-logos.png`,
    width: 90,
    component: <ButtonOutline link={`/partner`} text="Become A Partner" />,
  },
  {
    id: 1,
    heading: "HOMEMADE FOOD DELIVERED TO YOUR DOORSTEP",
    subHeading:
      "No need to remember any passwords just sign up using your mobile number and fill in the OTP.",
    imgPath: `${process.env.PUBLIC_URL}/buyer-mockups/otp.png`,
  },
  {
    id: 2,
    heading: "ORDER YOUR FAVOURITE MEALS",
    subHeading:
      "Your favourite homemade meals are just a few clicks away from you.",
    imgPath: `${process.env.PUBLIC_URL}/buyer-mockups/shop.png`,
  },
  {
    id: 3,
    heading: "ONLINE IS THE WAY FORWARD",
    subHeading:
      "We at ChefCities truly believe in Digital India which is why we accept online payments from India’s most secure payment gateway, Razorpay.",
    imgPath: `${process.env.PUBLIC_URL}/buyer-mockups/razorpay.png`,
  },
  {
    id: 4,
    heading: "TRACK ALL YOUR ORDERS",
    subHeading:
      "We are determined to give our chefs and customers the best services. Which is why all the orders are delivered by one of Mumbai’s best delivery services, WeFast.",
    imgPath: `${process.env.PUBLIC_URL}/buyer-mockups/track-order.png`,
  },
];

export const buyerTickMarks = [
  {
    id: 0,
    text: "Super fast delivery",
  },
  {
    id: 1,
    text: "Delicious Homemade food",
  },
  {
    id: 3,
    text: "Live Order Tracking",
  },
  {
    id: 4,
    text: "Hassle Free Ordering And Payment Process",
  },
];

export const buyerHomePage = {
  tickMarks: buyerTickMarks,
  heading: "HOMEMADE FOOD IS JUST ONE CLICK AWAY",
  subHeading:
    "ChefCities is one of the only platforms that brings food from Mumbai’s best homechefs right to your doorstep.",
  imgPathRight: `${process.env.PUBLIC_URL}/buyer-mockups/main-right.png`,
  imgPathLeft: `${process.env.PUBLIC_URL}/buyer-mockups/main-left.png`,
  imgRightAlt: "ChefCities App Home Screen",
  imgLeftAlt: "ChefCities App Login Screen",
};
