import Button from "@material-ui/core/Button";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import React from "react";
import { useHistory } from "react-router-dom";

interface ButtonOutlineProps {
  text: string;
  link?: string;
  onClick?: () => void;
}

const ButtonOutline = (props: ButtonOutlineProps) => {
  const history = useHistory();

  const onPress = () => {
    if (props.link) {
      history.push(props.link);
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className="m-4">
      <Button
        onClick={onPress}
        color="primary"
        variant="outlined"
        endIcon={<NavigateNextRoundedIcon />}
      >
        <div style={{ fontSize: 20 }}>{props.text}</div>
      </Button>
    </div>
  );
};

export default ButtonOutline;
