import { Button } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { RouteComponentProps } from "react-router-dom";
import MeraLottie from "../components/Lottie/Lottie";
import Heading from "../components/Text/Heading";
import SubHeading from "../components/Text/SubHeading";

interface NotFoundProps extends RouteComponentProps {}

const NotFound = ({}: NotFoundProps) => {
  const imageContainer: CSSProperties = {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div>
      <div className="container p-3">
        <div className="row">
          <div
            className={
              "col-sm-6 col-md-6 flex-column d-flex justify-content-center"
            }
          >
            <div className="align-items-center d-flex justify-content-center flex-column">
              <Heading alignCenter text={"404, Page Not Found"} />
              <SubHeading
                alignCenter
                text={"We're sorry, the page you requested could not be found"}
              />
              <Button
                className="m-5"
                variant="contained"
                color="primary"
                size="large"
                href={`${process.env.PUBLIC_URL}/`}
                style={{ color: "white" }}
              >
                Home
              </Button>
            </div>
          </div>
          <div className={"col-sm-6 col-md-6"}>
            <div style={imageContainer}>
              <MeraLottie path="animations/not-found.json" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
