import "./Footer.css";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import { externalLinks } from "../../data/externalLinks";

const Footer = () => {
  return (
    <footer className="site-footer mt-3">
      <div className="container">
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4 text-center p-2">
            <h3 className="fw-bold">More</h3>
            <ul className="list-unstyled">
              <li className="custom-link">
                <a
                  className="a-cancel custom-link"
                  href={`${process.env.PUBLIC_URL}/partner`}
                >
                  Register your own store
                </a>
              </li>
              <li className="custom-link">
                <a
                  className="a-cancel custom-link"
                  href={`${process.env.PUBLIC_URL}/privacy-policy`}
                >
                  Privacy Policy
                </a>
              </li>
              <li className="custom-link">
                <a
                  className="a-cancel custom-link"
                  href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>

          <div
            id="contact"
            className="col-sm-4 col-md-4 col-lg-4 text-center p-2"
          >
            <h3 className="fw-bold">Contact Us</h3>
            <ul className="list-unstyled">
              <li className="custom-link m-2 contact-li">
                <MailOutlineRoundedIcon />
                <a
                  className="a-cancel custom-link"
                  href="mailto:chefcities@gmail.com"
                >
                  {" "}
                  chefcities@gmail.com
                </a>
              </li>
              <li className="custom-link m-2 contact-li">
                <InstagramIcon />
                <a
                  className="a-cancel custom-link"
                  href="https://instagram.com/chefcities"
                >
                  {" "}
                  chefcities
                </a>
              </li>
              {/* <li className="custom-link m-2 contact-li">
                <PhoneInTalkOutlinedIcon />
                <a className="a-cancel custom-link" href="tel:+91-996-786-6352">
                  {" "}
                  +91 9967866352
                </a>
              </li> */}
            </ul>
          </div>

          <div className="col-sm-4 col-md-4 col-lg-4 text-center p-2 mb-2">
            <h3 className="fw-bold pb-3">Apps</h3>

            <h5>Customer</h5>
            <div className="d-flex align-items-center justify-content-center">
              <ul className="list-unstyled d-flex">
                <li className="custom-link">
                  <a href={externalLinks.buyerPlayStore}>
                    <img
                      width={160}
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </li>
                <li className="custom-link">
                  <a href={externalLinks.buyerAppStore}>
                    <img
                      width={150}
                      style={{ padding: 10 }}
                      alt="Download on the App Store"
                      src="/images/app-store-download.png"
                    />
                  </a>
                </li>
              </ul>
            </div>

            <h5>Partner</h5>
            <div className="d-flex align-items-center justify-content-center">
              <ul className="list-unstyled d-flex">
                <li className="custom-link">
                  <a href={externalLinks.sellerPlayStore}>
                    <img
                      width={160}
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </li>
                <li className="custom-link">
                  <a href={externalLinks.sellerAppStore}>
                    <img
                      width={150}
                      style={{ padding: 10 }}
                      alt="Download on the App Store"
                      src="/images/app-store-download.png"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <p className="text-center fs-6">
          © Copyright 2021 ChefCities. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
