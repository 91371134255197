import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import { Button } from "@material-ui/core";
import React from "react";

interface ContactInfoProps {}

const ContactInfo = ({}: ContactInfoProps) => {
  return (
    <div className="d-flex flex-column">
      <Button
        href="tel:+91-996-786-6352"
        variant="outlined"
        size="medium"
        color="primary"
        startIcon={<PhoneInTalkOutlinedIcon />}
        className="mb-2 mt-3 shadow"
      >
        +91 9967866352
      </Button>
      <Button
        href="mailto:chefcities@gmail.com"
        variant="outlined"
        size="medium"
        color="primary"
        style={{ textTransform: "none" }}
        startIcon={<MailOutlineRoundedIcon />}
        className="mb-2 shadow"
      >
        chefcities@gmail.com
      </Button>

      <Button
        href="https://instagram.com/chefcities"
        variant="outlined"
        size="medium"
        color="primary"
        style={{ textTransform: "none" }}
        startIcon={<InstagramIcon />}
        className="mb-2 shadow"
      >
        chefcities
      </Button>
    </div>
  );
};

export default ContactInfo;
