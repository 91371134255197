import React from "react";

const RefundPolicy = () => {
  return (
    <div style={{ margin: 15 }}>
      <h1 className="text-center mb-3">Refund Policy</h1>
      <p>
        ChefCities<span className="logo-dark-gold">.com</span>
      </p>

      <p>
        <b>
          This is an extension to our{" "}
          <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`}>
            Terms and Conditions
          </a>
        </b>
      </p>

      <div>
        <p>
          A customer under no circumstances can cancel an order which has
          already been placed.
        </p>

        <p>
          A seller may due to unforeseen circumstances not be capable of
          accepting an order for which the customer has already paid. In this
          situation the entire amount paid by the customer is completely
          refundable.
        </p>

        <p>
          If there is any damage to the product during delivery only then will
          the customer be entitled to a refund after scrutiny by ChefCities. The
          seller too will be compensated for the entire amount of the product
          less commission fees.
        </p>

        <p>
          The customer will also be entitled to a refund if any damage caused to
          the product prior to delivery by the seller. In this case, no
          compensation will be provided to the seller.
        </p>

        <p>
          Even after our sellers best efforts some prices may be incorrectly
          added on the website. In this case the customer may accept the new
          prices or may opt for a refund.
        </p>

        <p>
          The customer will also be entitled to a refund if an incorrect order
          has been delivered to him/her.
        </p>

        <p>The customer will receive any refund amount in 5-7 business days</p>

        <p>Our decisions regarding refund will be final and binding</p>
      </div>
    </div>
  );
};

export default RefundPolicy;
