import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

interface LottieProps {
  path: string;
}

const MeraLottie = ({ path }: LottieProps) => {
  const animationRef = useRef<HTMLElement>();

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef.current as HTMLElement,
      loop: false,
      autoplay: true,
      path: path,
    });
  }, []);

  return (
    <div ref={animationRef as React.LegacyRef<HTMLDivElement> | undefined} />
  );
};

export default MeraLottie;
