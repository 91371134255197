import { Typography } from "@material-ui/core";
import React, { CSSProperties } from "react";

interface HeadingProps {
  text: string;
  alignCenter?: boolean;
}

const Heading = ({ text, alignCenter = true }: HeadingProps) => {
  const textStyles: CSSProperties = {
    fontSize: 30,
    fontWeight: "bold",
  };
  if (alignCenter) {
    textStyles["textAlign"] = "center";
  }

  return (
    <Typography color="primary" style={textStyles}>
      {text}
    </Typography>
  );
};

export default Heading;
