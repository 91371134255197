import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ margin: 15 }}>
      <h1>Privacy Policy</h1>

      <p>
        This Privacy Policy (“Policy”) describes the policies and procedures on
        the collection, use, disclosure and protection of your information when
        you use our website located at ChefCities.com (“ChefCities”, “Company”,
        “we”, “us” and “our”), a partnership firm established under the laws of
        India having its registered office at The terms “you” and “your” refer
        to the user of the ChefCities. The term “Services” refers to any
        services offered by ChefCities.
      </p>
      <p>
        Please read this Policy before using the ChefCities or submitting any
        personal information to ChefCities. This Policy is a part of and
        incorporated within, and is to be read along with, the Terms of Use.
      </p>

      <h6>YOUR CONSENT</h6>
      <p>
        By using the ChefCities website and the Services, you agree and consent
        to the collection, transfer, use, storage, disclosure and sharing of
        your information as described and collected by us in accordance with
        this Policy. If you do not agree with the Policy, please do not use or
        access the ChefCities website.
      </p>

      <h6>POLICY CHANGES</h6>
      <p>
        We may occasionally update this Policy and such changes will be posted
        on this page. If we make any significant changes to this Policy we will
        endeavour to provide you with reasonable notice of such changes, such as
        via prominent notice on the ChefCities Website on record and where
        required by applicable law, we will obtain your consent. To the extent
        permitted under the applicable law, your continued use of our Services
        after we publish or send a notice about our changes to this Policy shall
        constitute your consent to the updated Policy.
      </p>

      <h6>LINKS TO OTHER WEBSITES</h6>
      <p>
        The ChefCities Website may contain links to other websites. Any personal
        information about you collected whilst visiting such websites is not
        governed by this Policy. ChefCities shall not be responsible for and has
        no control over the practices and content of any website accessed using
        the links contained on the ChefCities Website. This Policy shall not
        apply to any information you may disclose to any of our service
        providers/service personnel which we do not require you to disclose to
        us or any of our service providers under this Policy.
      </p>

      <h6>INFORMATION WE COLLECT FROM YOU</h6>
      <p>
        We will collect and process the following information about you,
        Information you give us - This includes information submitted when you:
        <p>
          <ul>
            <li>
              {" "}
              Create or update your ChefCities account, which may include your
              name, phone number, login name and password, address, payment and
              banking information.
            </li>
            <li>
              {" "}
              Provide content to us, which may include reviews, ordering details
              and history, favorite vendors, special merchant requests, contact
              information of people you refer to us and other information you
              provide on the ChefCities Website (“Your Content”).
            </li>
            <li>
              {" "}
              Use our Services, we may collect and store information about you
              to process your requests and automatically complete forms for
              future transactions, including (but not limited to) your phone
              number, address, email, billing information and credit or payment
              card information.
            </li>
            <li> Correspond with ChefCities for customer support;</li>
            <li>
              {" "}
              Participate in the interactive services offered by the ChefCities
              Website such as discussion boards, competitions, promotions or
              surveys, other social media functions or make payments etc.,
            </li>
            <li> Report problems for troubleshooting.</li>
            <li>
              {" "}
              If you sign up to use our Services as a merchant or a seller, we
              may collect location details, copies of government identification
              documents and other details (KYC), call and SMS details.
            </li>
          </ul>
          <p>
            {" "}
            Information we collect about you - With regard to each of your
            visits to the ChefCities Website, we will automatically collect and
            analyse the following demographic and other information:
          </p>
          <ul>
            <li>
              {" "}
              When you communicate with us (via email, phone, through the
              ChefCities Website or otherwise), we may maintain a record of your
              communication;
            </li>
            <li>
              {" "}
              Location information: Depending on the Services that you use, and
              your settings or device permissions, we may collect your real time
              information, or approximate location information as determined
              through data such as GPS, IP address;
            </li>
            <li>
              {" "}
              Usage and Preference Information: We collect information as to how
              you interact with our Services, preferences expressed and settings
              chosen. ChefCities Website, we may collect user activity and
              browsing history within the ChefCities.
            </li>
            <li>
              {" "}
              Transaction Information: We collect transaction details related to
              your use of our Services, and information about your activity on
              the Services, including the full Uniform Resource Locators (URL),
              the type of Services you requested or provided, comments, domain
              names, search results selected, number of clicks, information and
              pages viewed and searched for, the order of those pages, length of
              your visit to our Services, the date and time you used the
              Services, amount charged, details regarding application of
              promotional code, methods used to browse away from the page and
              any phone number used to call our customer service number and
              other related transaction details;
            </li>
            <li>
              {" "}
              Device Information: We may collect information about the devices
              you use to access our Services, including the hardware models,
              operating systems and versions, software, file names and versions,
              preferred languages, unique device identifiers, advertising
              identifiers, serial numbers, device motion information and mobile
              network information.
            </li>
            <li>
              {" "}
              If you are a partner restaurant, merchant or a seller, we may,
              additionally, record your calls with us made from the device used
              to provide Services, related call details, SMS details location
              and address details.
            </li>
          </ul>
          <p> Information we receive from other sources -</p>
          <li>
            {" "}
            If you are a seller, we may, additionally, receive feedback and
            rating from other users.
          </li>

          <h6>COOKIES</h6>
          <p>
            Our ChefCities Website, may use cookies, pixel tags, web beacons,
            mobile device IDs, “flash cookies” and similar files or technologies
            to collect and store information with respect to your use of the
            Services and third-party websites. Cookies are small files that are
            stored on your browser or device by websites, apps, online media and
            advertisements. We use cookies and similar technologies for purposes
            such as:
          </p>
          <ul>
            <li> Authenticating users;</li>
            <li> Remembering user preferences and settings;</li>
            <li> Determining the popularity of content;</li>
            <li>
              {" "}
              Delivering and measuring the effectiveness of advertising
              campaigns;
            </li>
            <li>
              {" "}
              Analysing site traffic and trends, and generally understanding the
              online behaviours and interests of people who interact with our
              services.
            </li>
            <li>
              To modify your cookie settings, please visit your browser’s
              settings. By using our Services with your browser settings to
              accept cookies, you are consenting to our use of cookies in the
              manner described in this section.
            </li>
          </ul>

          <h6>USES OF YOUR INFORMATION</h6>
          <p>
            {" "}
            We use the information we collect for following purposes, including:
          </p>
          <ul>
            <li>
              {" "}
              To provide, personalise, maintain and improve our products and
              services, such as to enable deliveries and other services, enable
              features to personalise your ChefCities account;
            </li>
            <li>
              {" "}
              To carry out our obligations arising from any contracts entered
              into between you and us and to provide you with the relevant
              information and services;
            </li>
            <li>
              {" "}
              To administer and enhance the security of our ChefCities Website
              and for internal operations, including troubleshooting, data
              analysis, testing, research, statistical and survey purposes;
            </li>
            <li>
              {" "}
              To provide you with information about services we consider similar
              to those that you are already using, or have enquired about, or
              may interest you. If you are a registered user, we will contact
              you by electronic means (e-mail or SMS or telephone) with
              information about these services;
            </li>
            <li>
              {" "}
              To understand our users (what they do on our Services, what
              features they like, how they use them, etc.), improve the content
              and features of our Services (such as by personalizing content to
              your interests), process and complete your transactions, make
              special offers, provide customer support, process and respond to
              your queries;
            </li>
            <li>
              {" "}
              To generate and review reports and data about, and to conduct
              research on, our user base and Service usage patterns;
            </li>
            <li>
              {" "}
              To allow you to participate in interactive features of our
              Services, if any; or
            </li>
            <li>
              {" "}
              To measure or understand the effectiveness of advertising we serve
              to you and others, and to deliver relevant advertising to you.
            </li>
            <li>
              {" "}
              If you are a partner restaurant or merchant or seller, to track
              the progress of delivery or status of the order placed by our
              customers.
            </li>
          </ul>
          <p>
            We analyse the log files of our ChefCities Website that may contain
            Internet Protocol (IP) addresses, browser type and language,
            Internet service provider (ISP), referring, app crashes, page viewed
            and exit websites and applications, operating system, date/time
            stamp, and clickstream data. This helps us to administer the
            website, to learn about user behaviour on the site, to improve our
            product and services, and to gather demographic information about
            our user base as a whole.
          </p>

          <h6>DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</h6>
          <p>
            We may share your information that we collect for following
            purposes:
          </p>
          <ul>
            <li>
              {" "}
              With Service Providers: We may share your information with our
              vendors, consultants, marketing partners, research firms and other
              service providers or business partners, such as Payment processing
              companies, to support our business. For example, your information
              may be shared with outside vendors to send you emails and messages
              or push notifications to your devices in relation to our Services,
              to help us analyze and improve the use of our Services, to process
              and collect payments. We also may use vendors for other projects,
              such as conducting surveys or organizing sweepstakes for us.
            </li>
            <li>
              {" "}
              With Partner Restaurants/Merchant: While you place a request to
              order food through the ChefCities Website, your information is
              provided to us and to the restaurants/merchants with whom you may
              choose to order. In order to facilitate your online food order
              processing, we provide your information to that
              restaurant/merchant in a similar manner as if you had made a food
              order directly with the restaurant. If you provide a mobile phone
              number, ChefCities may send you text messages regarding the
              order’s delivery status.
            </li>
            <li>
              {" "}
              With Other Users: If you are a seller, we may share your name,
              phone number and/or profile picture (if applicable), tracking
              details with other users to provide them the Services.
            </li>
          </ul>
          <p>
            {" "}
            For Crime Prevention or Investigation: We may share this information
            with governmental agencies or other companies assisting us, when we
            are:
          </p>
          <ul>
            <li>
              {" "}
              Obligated under the applicable laws or in good faith to respond to
              court orders and processes; or
            </li>
            <li>
              {" "}
              Detecting and preventing against actual or potential occurrence of
              identity theft, fraud, abuse of Services and other illegal acts;
            </li>
            <li>
              {" "}
              Responding to claims that an advertisement, posting or other
              content violates the intellectual property rights of a third
              party;
            </li>
            <li>
              {" "}
              Under a duty to disclose or share your personal data in order to
              enforce our Terms of Use and other agreements, policies or to
              protect the rights, property, or safety of the Company, our
              customers, or others, or in the event of a claim or dispute
              relating to your use of our Services. This includes exchanging
              information with other companies and organisations for the
              purposes of fraud detection and credit risk reduction.
            </li>
            <li>
              {" "}
              For Internal Use: We may share your information with any present
              or future employees/partners
            </li>
            <li>
              {" "}
              We may share your information other than as described in this
              Policy if we notify you and you consent to the sharing.
            </li>
          </ul>

          <h6>DATA SECURITY PRECAUTIONS</h6>
          <p>
            We have in place appropriate technical and security measures to
            secure the information collected by us.
          </p>
          <p>
            You are advised not to send your full credit/debit card details
            through unencrypted electronic websites. Where we have given you (or
            where you have chosen) a username and password which enables you to
            access certain parts of the ChefCities Website, you are responsible
            for keeping these details confidential. We ask you not to share your
            password with anyone.
          </p>
          <p>
            Please be aware that the transmission of information via the
            internet is not completely secure. Although we will do our best to
            protect your personal data, we cannot guarantee the security of your
            data transmitted through the ChefCities Website. Once we have
            received your information, we will use strict physical, electronic,
            and procedural safeguards to try to prevent unauthorised access.
          </p>

          <h6>OPT-OUT</h6>
          <p>
            When you sign up for an account, you are opting in to receive emails
            or text messages from ChefCities. You can log in to manage your
            email preferences [here] or you can follow the “unsubscribe”
            instructions in commercial email messages, but note that you cannot
            opt out of receiving certain administrative notices, service
            notices, or legal notices from ChefCities.
          </p>

          <h6>CHEFCITIES WEBSITE SECURITY</h6>
          <p>
            If you have any queries relating to the processing or usage of
            information provided by you in connection with this Policy, please
            email us at chefcities@gmail.com
          </p>
          <p>
            Further, please note that the ChefCities Website stores your data
            with the cloud website of Amazon Web Services provided by Amazon Web
            Services, Inc., which may store this data on their servers located
            outside of India. Amazon Web Services has security measures in place
            to protect the loss, misuse and alteration of the information,
            details of which are available at{" "}
            <a href="https://aws.amazon.com/">https://aws.amazon.com/</a>. The
            privacy policy adopted by Amazon Web Services are detailed in{" "}
            <a href="https://aws.amazon.com/privacy">
              https://aws.amazon.com/privacy
            </a>{" "}
            . In the event you have questions or concerns about the security
            measures adopted by Amazon Web Services, you can contact their data
            protection / privacy team / legal team or designated the grievance
            officer at these organisations, whose contact details are available
            in its privacy policy, or can also write to our Grievance Officer at
            the address provided above.
          </p>
        </p>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
