import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  direction: "up" | "down";
}

const HideOnScroll = ({ children, direction = "down" }: Props) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction={direction} in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
