import React from "react";

const DeliveryPolicy = () => {
  return (
    <div style={{ margin: 15 }}>
      <h1 className="text-center mb-3">Delivery Policy</h1>
      <p>
        ChefCities<span className="logo-dark-gold">.com</span>
      </p>

      <p>
        <b>
          This is an extension to our{" "}
          <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`}>
            Terms and Conditions
          </a>
        </b>
      </p>

      <div>
        <p>
          If the seller is unable to dispatch your order on the day you selected
          for delivery. A new date will be suggested to you. If you disagree,
          your order will be cancelled with a complete refund.
        </p>

        <p>
          We provide the seller with delivery services via our delivery partner.
          In the case of any issues arising due to delivery (Delivery on the
          incorrect date, Products damaged during delivery, etc.) we will
          provide the customer with a complete refund and the seller will be
          compensated too.
        </p>

        <p>
          If no one is present at the delivery address the seller will keep the
          product till its shelf life. If the seller disposes the product in
          this scenario the customer will not be entitled to a refund.
        </p>

        <p>
          Neither we nor the Seller shall be liable for any delay or failure in
          the delivery of the items that is caused by your failure to provide
          the Seller with adequate delivery instructions or any other
          instructions that are relevant to the supply of the items including
          erroneous address, lack of permission to enter or refusal of the
          order.
        </p>

        <p>
          The date of delivery will be mentioned to the customer or the customer
          can opt for a particular date before ordering the products. The
          products will be delivered to the customer in a few hours depending on
          the distance after the seller has clicked on the ‘Request for
          delivery’ option. Approximate delivery time, if delivery requested by
          customer is the next day, will be 24 hours.
        </p>

        <p>The customer will receive any refund amount in 5-7 business days</p>
      </div>
    </div>
  );
};

export default DeliveryPolicy;
