import AOS from "aos";
import "aos/dist/aos.css";
import React, { CSSProperties, useEffect } from "react";
import { ANIMATION_DURATION } from "../../constants/colors";
import Seperator from "../Seperator/Seperator";
import Heading from "../Text/Heading";
import SubHeading from "../Text/SubHeading";

interface MockupSectionProps {
  heading: string;
  subHeading: string;
  imagePath: string;
  injectComponent?: React.ReactNode;
  imageWidth?: number;
  reverse?: boolean;
}

const MockupSection = ({
  heading,
  subHeading,
  imagePath,
  injectComponent,
  imageWidth = 50,
  reverse = false,
}: MockupSectionProps) => {
  const imageContainer: CSSProperties = {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div>
      <Seperator />
      <div className="container p-3">
        <div className="row">
          <div
            className={
              "col-sm-6 col-md-6 flex-column d-flex justify-content-center" +
              (reverse ? " order-md-2" : "")
            }
          >
            <div className="align-items-center d-flex justify-content-center flex-column">
              <div data-aos="zoom-in" data-aos-duration={ANIMATION_DURATION}>
                <Heading alignCenter text={heading} />
              </div>
              <div
                className="align-items-center d-flex justify-content-center flex-column"
                data-aos="fade-up"
                data-aos-duration={ANIMATION_DURATION}
              >
                <SubHeading alignCenter text={subHeading} />
                {injectComponent}
              </div>
            </div>
          </div>

          <div
            className={"col-sm-6 col-md-6" + (reverse ? " order-md-1" : "")}
            data-aos="zoom-in-up"
            data-aos-duration={ANIMATION_DURATION}
          >
            <div style={imageContainer}>
              <img
                style={{ width: `${imageWidth}%` }}
                src={imagePath}
                alt={heading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockupSection;
