import BottomContainer from "../components/BottomContainer/BottomContainer";
import HomePage from "../components/HomePage/HomePage";
import MockupSection from "../components/MockupSection/MockupSection";
import { sellerHomePage, sellerMockupData } from "../data/sellerMockupData";
import React from "react";
import { externalLinks } from "../data/externalLinks";

interface PartnerProps {}

const Partner = ({}: PartnerProps) => {
  return (
    <div>
      <HomePage homePageData={sellerHomePage} />

      {sellerMockupData.map((obj) => (
        <MockupSection
          key={obj.id}
          reverse={obj.id % 2 === 0 ? true : false}
          heading={obj.heading}
          subHeading={obj.subHeading}
          imagePath={obj.imgPath}
          imageWidth={obj.width}
          injectComponent={obj.component}
        />
      ))}

      <BottomContainer
        playStoreLink={externalLinks.sellerPlayStore}
        appStoreLink={externalLinks.sellerAppStore}
      />
    </div>
  );
};

export default Partner;
